#footer {
  background-color: #87cefa;
  color: white;
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

#footerText {
  font-size: 1.6rem;
}

#allFooterImg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-around;
}

.footerLinkedinImg{
  width: 100%;
  max-width: 20px;
  height: 20px;
  margin-right: 15px;
}

.footerEmailImg {
  width: 100%;
  max-width: 20px;
  height: 20px;
  margin-right: 15px;
}

.footerGitImg {
  width: 100%;
  max-width: 20px;
  height: 20px;
  margin-right: 15px;
}

.footerLinkedinImg:hover, .footerEmailImg:hover, .footerGitImg:hover {
  opacity: 0.5;
}

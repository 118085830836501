* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  height: 100vh;
  background-color: #DDEEFF;
  font-family: 'Bebas Neue';
}

.imgWelcome {
  border-radius: 43%;
}

.skillshomee {
  height: 120vh;
}

.imgDiv {
  margin-top: 30px;
}

.imgWelcome {
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
  margin-right: 10vh;
}

.welcome {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.welcomeText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text1 {
  color: black;
  font-size: 11.2rem;
  transition: all 0.3s ease-out;
}

.text1:hover {
  color: #87CEFA;
  transform: translateY(-10px);
}

.textp {
  color: black;
  font-size: 2rem;
  text-align: center;
}

.btn-home {
  font-size: 2rem;
  font-family: 'Bebas Neue';
  margin-top: 30px;
  cursor: pointer;
  background-color: white;
  width: 100%;
  max-width: 230px;
  height: 30px;
  border-radius: 5px;
  transition: all 0.5s ease 0s;
  
}

.btn-home:hover {
  background-color: #87CEFA;
}

#profilePic {
  width: 100%;
  max-width: 220px;
  margin-bottom: 25px;
  border-radius: 100%;
}


.aboutTextHome {
  width: 100%;
  max-width: 500px;
  text-align: center;
  color: white;
}

.aboutTextHome2 {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 110vh;
}

#aboutTitleHome {
  margin-top: 30px;
  font-size: 8rem;
  color: black;
} 

#pAboutHome {
  font-size: 1.8rem;
  word-spacing: 2px;
  color: black;
}

#allContactHome {
  text-align: center;
  height: 80vh;
 }
 
 #allContactTextHome {
  margin-top: 60px;
  font-size: 3rem;
  text-align: center;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  }

  .contactTitleHome {
    margin-top: 100px;
    margin-bottom: 130px;
    color: white;
    text-align: center;
    font-size: 8rem;
  }
 
  #allContactImgHome {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 30px;
 } 
 
 .linkedinImgHome {
  /* background-color: white; */
  /* border-radius: 10px; */
  width: 100%;
  max-width: 40px;
  height: 40px;
  transition: all 0.3s ease-out;
 }

.linkedinImgHome:hover {
  opacity: 0.5;
  transform: translateY(-10px);
}
 
.emailImgHome {
  /* border-radius: 30px; */
  width: 100%;
  max-width: 40px;
  height: 40px;
  transition: all 0.3s ease-out;
}

.emailImgHome:hover {
  opacity: 0.5;
  transform: translateY(-10px);
}
 
.linkedinAHome {
   text-decoration: none;
   color: black;
 }
 
.emailAHome {
   text-decoration: none;
   color: black;
}

.projectsTitleHome {
  margin-top: 30px;
  color: black;
  text-align: center;
  font-size: 8rem;
  /* margin-bottom: 10vh; */
}


.pProjects {
  margin-bottom: 10vh;
}

.projectsHome {
  margin-top: 5%; /* Ajuste a margem para ficar mais balanceado em telas maiores */
  display: flex;
  background-color: white;
  height: auto; /* Usar altura automática em vez de um valor fixo */
  min-height: 400px; /* Defina uma altura mínima para evitar que a seção fique muito pequena */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px; /* Adiciona um pouco de espaçamento nas laterais */
}

@media (min-width: 1940px) {
  html {
    font-size: 70%;
  }

  .btn-home {
    font-size: 2rem;
    font-family: 'Bebas Neue';
    margin-top: 30px;
    cursor: pointer;
    background-color: white;
    width: 100%;
    max-width: 330px;
    height: 40px;
    border-radius: 5px;
    transition: all 0.5s ease 0s;
    
  }

}

@media (max-width: 1100px) {
  html {
    font-size: 52%;
  }
  .imgWelcome {
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 350px;
    /* margin-right: 30vh; */
  }

}


@media (max-width: 882px) {
  html {
    font-size: 52%;
  }

  .imgWelcome {
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 280px;
    margin-right: 0vh;
  }

  .welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }

  .text1 {
    color: white;
    font-size: 9.2rem;
    transition: all 0.3s ease-out;
    margin-left: 20px;
    margin-right: 20px;
  }

  #profilePic {
    width: 40%;
    max-width: 220px;
    margin-bottom: 25px;
  }

  .textp {
    margin-left: 50px;
    margin-right: 50px;
  }


  .contactTitleHome {
    font-size: 5rem;
  }

  .projectsTitleHome {
    font-size: 5rem;
  }

  
  
  .btn-home {
    width: 50%;    
  }

  #allContactTextHome {
    font-size: 2.4rem;
  }

}

@media (max-width: 768px) {
  .projectsHome {
    margin-top: 10%; /* Ajuste a margem para dispositivos móveis */
    height: auto; /* Garante que a altura se ajuste conforme o conteúdo */
  }
}

@media (max-width: 500px) {
  html {
    font-size: 45%;
  }

  .imgWelcome {
    width: 100%;
    height: 100%;
    max-width: 230px;
    max-height: 230px;
    margin-right: 0vh;
  }

  .contactTitleHome {
    margin-top: 150px;
  }

} 

@media (max-width: 330px) {
  html {
    font-size: 40%;
  }

  .imgWelcome {
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
    margin-right: 0vh;
  }

  .projectsHome {
    margin-top: 15%; /* Ajusta mais para telas muito pequenas */
  }

}

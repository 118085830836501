.skill-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.skillTitleHome {
  margin-top: 30px;
  margin-bottom: 5%;
  color: black;
  text-align: center;
  font-size: 8rem;
}

.frontTitle {
  text-align: center;
  align-items: center;
  font-size: 6rem;
  color: black;
}

.classSkillsTItle {
  margin: auto;
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.skillsHome {
  width: 30%;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  align-items: center;
  grid-gap: 10px;
  /* border: solid 5px blue; */
}

.skillsImgHome {
  display: flex;
  width: 100%; 
  max-width: 60px;
  justify-content: center;
  align-items: center;
}


.card {
  border-radius: 5px;
  box-shadow: 7px 7px 13px 0 #87CEFA;
  padding: 10px;
  width: 100%; 
  max-width: 100px; 
  transition: all 0.3s ease-out;
  margin-bottom: 20px;
}

.b14:last-child {
  grid-column-start: 3;
  grid-column-end: 3;
}


.card:hover {
  transform: translateY(-10px);
  cursor: pointer;
  background-color: #87CEFA;
}

.card p {
  color: black;
  font-size: 1.6rem;
  margin-top: 8px;
}

.skillsKnow {
  margin: auto;
  height: 300px;
  color: black;
  width: 100%;
  max-width: 300px;
}

 .skillstexts {
  font-size: 2rem;
  margin-top: -40%;
  /* margin-bottom: 200px; */
}

@media (max-width: 980px) {

  .skillTitleHome {
    color: black;
    text-align: center;
    font-size: 8rem;
  }

  .skill-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  

  .skillsHome {
    width: 30%;
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    align-items: center;
    grid-gap: 10px;
    /* border: solid 5px blue; */
  }
  
  .skillsImgHome {
    display: flex;
    width: 100%; 
    max-width: 95px;
    justify-content: center;
    align-items: center;
  }
  
  .card {
    border-radius: 5px;
    box-shadow: 7px 7px 13px 0 #87CEFA;
    padding: 10px;
    width: 70%; 
    max-width: 100px; 
    transition: all 0.3s ease-out;
    margin-bottom: 20px;
  }
  
  .card:hover {
    transform: translateY(-10px);
    cursor: pointer;
    background-color: #87CEFA;
  }

  .skillsKnow {
    display: none;
  }

  @media (max-width: 750px) {
    .card {
      border-radius: 5px;
      box-shadow: 7px 7px 13px 0 #87CEFA;
      padding: 10px;
      width: 100%; 
      max-width: 80px; 
      transition: all 0.3s ease-out;
      margin-bottom: 10px;
    }

    .skillTitleHome {
      margin-top: 30px;
      margin-bottom: 5%;
      color: black;
      text-align: center;
      font-size: 6rem;
    }
    
    .frontTitle {
      text-align: center;
      align-items: center;
      font-size: 4rem;
      color: black;
    }

    .skillsHome {
      width: 30%;
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      align-items: center;
      grid-gap: 10px;
      /* border: solid 5px blue; */
    }
    .b14:last-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  @media (max-width: 500px) {
    .skillsHome {
      margin-top: 15%;
      width: 30%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      align-items: center;
      grid-gap: 10px;
      /* border: solid 5px blue; */
    }
    .b14:last-child {
      grid-column-start: 1;
      grid-column-end: 1;
    }
  }

}
.projects {
  height: 100%;
}

.projectsTitle {
  color: black;
  margin-top: 100px;
  font-size: 8rem;
  text-align: center;
  margin-bottom: 80px;  
}
.pProjects {
  color: black;
  text-align: center;
  margin-top: 3vh;
  font-size: 1.7rem;
}

.projectsHomeCard {
  display: flex;
}

@media (max-width: 1100px) {
  .projectsTitle {
    font-size: 7rem;
    /* margin-top: 300px; */
  }
  .projects {
    height: 100vh;
  }
  
}

@media (max-width: 500px) {
  .projects {
    height: 100%;
  }
}

@media (max-width: 350px) {
  .projects {
    height: 100%;
  }
}
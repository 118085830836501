header {
  background-color: #87CEFA;
}

header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 980px;
  margin: auto;
}

header img {
  border-radius: 100%;
  width: 70px;
  margin-left: 20px;
  padding: 15px 0;
}

header nav ul {
  display: flex;
}

header nav ul li {
  list-style: none;
}

header nav ul li a {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-size: 2rem; 
  padding: 2.4rem;
  transition: all 250ms linear 0s;
  /* margin-right: 9.5rem; */
}

header nav ul li a:hover {
  opacity: 0.5;
}

@media (max-width: 820px) {
 nav {
        display: none;
    }

    .one,
    .two,
    .three {
        background-color: #fff;
        height: 5px;
        width: 100%;
        margin: 6px auto;
        

        transition-duration: 0.3s;
    }

    .menu-toggle {
        width: 40px;
        height: 30px;
        /* margin-right: 20px; */
    }

    .menu-section.on {
        position: relative;
        top:0;
        left: 0;
        /* margin-left: -20px; */
        width: 100vw;

        background-color: #87CEFA;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menu-section.on nav {
        display: block;
        
    }

    .menu-section.on .menu-toggle {
        position: absolute;
        right: 5px;
        top: 15px;
        
    }

    .menu-section.on .menu-toggle .one {
        transform: rotate(45deg) translate(7px, 7px);
    }

    .menu-section.on .menu-toggle .two {
        opacity: 0;
    }

    .menu-section.on .menu-toggle .three {
        transform: rotate(-45deg) translate(8px, -9px);
    }

    .menu-section.on nav ul {
        text-align: center;
        display: block;
        
    }

    .menu-section.on nav ul a{
        margin-top: 20px;
        transition-duration: 0.5s;
        font-size: 3rem;
        line-height: 4rem;
        display: block;
    }

}
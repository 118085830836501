.skillTitle {
  margin-top: 10%; 
  font-size: 8rem;
}

.skill {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.imgt {
  display: flex;
  width: 65px;
  height: 65px;
  justify-content: center;
  align-items: center;
  object-fit: contain; /* Ajusta a imagem sem cortá-la */
}

.skillsFooter {
  position: fixed; 
  bottom: 0;
  width: 100%;
  display: flex;
}

@media (max-width: 980px) {
  .imgt {
    width: 70%; 
    max-width: 65px;
    height: auto; /* Ajusta a altura para preservar a proporção */
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 820px) {

  .skillTitle {
    /* margin-top: 250px; */
    font-size: 7rem;
  }
}
.allProjects {
  max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 2fr));
    align-items: center;
    grid-gap: 30px;
    justify-items: center;
    align-content: center;
}

.imgItem {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: red;
  width: 100%;
  max-width: 400px;
  height: 400px;
}


.cardsImg {
  width: 90%;
  /* max-width: 200px; */
  height: 90%;
}

.imgsCard {
  display: flex;

}

.cards {
  display: flex;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 300px;
  max-width: 400px;
  margin-bottom: 20px;
  transition: 0.6s;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 4px 6px gray;
  background-color: #DDEEFF;
}

.cards:hover {
  box-shadow: 0px 1px 4px 6px #87CEFA;
  transition: 0.3s;
}

.cardLogo {
  width: 100%;
  max-width: 22px;
  height: 20px;
  margin-right: 10px;
}

.pCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  align-items: center;
  font-size: 1.5rem;
  color: black;
}

.cardsec2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.cardfir {
  font-size: 2.5rem;
  color: black;
}

.soon {
  font-size: 5vw; /* Responsivo, ajusta conforme a largura da tela */
  transform: rotate(-45deg);
  color: black;
  word-wrap: break-word;
  white-space: normal;
}

@media (max-width: 500px) {
  .soon {
      font-size: 10vw; /* Aumenta o tamanho da fonte em telas pequenas */
  }
}


@media (max-width: 1134px) {
  .allProjects {
      grid-gap: 0px;
  }

  .allProjects {
    max-width: 98%;
    grid-gap: 30px;
  }
  
  .imgItem {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    max-width: 300px;
    height: 150px;
  }

  .cards {
    display: flex;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    height: 270px;
    max-width: 350px;
    margin-bottom: 20px;
    transition: 0.6s;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
}

@media (max-width: 658px) {
  .imgItem {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    max-width: 300px;
    height: 180px;
  }
  .cards {
    margin-bottom: 30px;
    display: flex;
    border-radius: 5px;
    padding: 17px;
    width: 90%;
    height: 100%;
    max-height: 365px;
    max-width: 260px;
  }
} 

@media (max-width: 500px) {
  .allProjects {
    max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 20px;
      justify-items: center;
      align-content: center;
  }

  .cards {
    display: flex;
    border-radius: 5px;
    padding: 10px;
    width: 490px;
    height: 200px;
  }
  
  @media (max-width: 330px) {
    .cards {
      display: flex;
      border-radius: 5px;
      padding: 10px;
      width: 190px;
      height: 190px;
    }
}
}
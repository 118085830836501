#allContact {
  text-align: center;
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center
}

#allContactText {
  font-size: 3rem;
  text-align: center;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
 }

#allContactImg {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 30px;
  margin-bottom: 30px;
}

.contactImg {
  margin-top: 10px;
  border-radius: 5px;
  /* box-shadow: 7px 7px 13px 0 rgba(50, 50, 50, 0.22); */
  padding: 10px;
  width: 100%; 
  max-width: 150px; 
  transition: all 0.3s ease-out;
}

.contactImg:hover {
  transform: translateY(-10px);
  cursor: pointer;
  opacity: 0.5;
}

.linkedinImg {
  width: 100%;
  max-width: 60px;
  height: 60px;
}

.emailImg {
  width: 100%;
  max-width: 60px;
  height: 60px;
}

.linkedinA {
  text-decoration: none;
  color: white;
}

.emailA {
  text-decoration: none;
  color: white;
}

#contactProfilePic {
  width: 100%;
  max-width: 300px;
}

.contactTitle {
  margin-top: 100px;
  font-size: 8rem;
  color: white;
  margin-bottom: 60px;
}

.contactText {
  color: white;
}

.contactFooter {
  background-color: #DCDCDC;
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
}

@media (max-width: 820px) {
  .contactFooter {
    background-color: #DCDCDC;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
  }

  #allContact {
    height: 700px;
  }

  .contactTitle {
    font-size: 7rem;
    /* margin-top: 180px; */
    /* margin-top: 2px; */
  }

  #allContactText {
    font-size: 2.8rem;
    margin-right: 3px;
    margin-left: 3px;
  }

  .linkedinImg {
    /* margin-top: 100px; */
    width: 30px;
    max-width: 60px;
    height: 30px;
  }
  
  .emailImg {
    /* margin-top: 100px; */
    width: 30px;
    max-width: 60px;
    height: 30px;
  }
}
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.aboutText {
  width: 100%;
  max-width: 500px;
  text-align: center;
  color: black;
}

#aboutTitle {
  margin-top: 100px;
  font-size: 8rem;
} 

#pAbout {
  font-size: 1.6rem;
  word-spacing: 2px;
  margin-bottom: 20px;
  /* height: 500px; */
}

#profilePicAbout {
  width: 100%;
  max-width: 220px;
  margin-bottom: 25px;
  border-radius: 100%;
}
@media (max-width: 820px) {
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 700px;
    justify-content: center;
  }
  #aboutTitle {
    font-size: 7rem;
    /* margin-top: 100px; */
  }

  .aboutText {
    width: 100%;
    max-width: 500px;
    text-align: center;
    color: black;
    /* margin-top: 130px; */
  }

  #pAbout {
    font-size: 1.4rem;
    word-spacing: 2px;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 30px;
  }

  #profilePicAbout {
    /* margin-top: 40px; */
    width: 160px;
  }
  
}